<template>
  <div>
    <div>
      <el-select v-if="$store.state.Login.Jurisdiction == 0" size="medium" v-model="Factory"
        style="width: 150px; padding: 0px 0px 0px 10px;" clearable :placeholder="$t('system.选择厂区')">
        <el-option v-for="item in $PublicJs.factory" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-date-picker style="width: 140px; padding: 0px 0px 0px 10px;" v-model="Dates" type="date" placeholder="选择日期"
        format="yyyy-MM-dd" value-format="yyyy-MM-dd">
      </el-date-picker>
      <el-select size="medium" v-model="Dayscount" style="width: 90px; padding: 0px 0px 0px 10px;" filterable
        allow-create>
        <el-option label="180" :value="180" />
        <el-option label="365" :value="365" />
      </el-select>
      <el-input size="medium" clearable v-model="Text" style="width: 270px; padding: 0px 10px 0px 10px;"
        :placeholder="$t('All.品名') + '/' + $t('All.工单号') + '/' + $t('All.系统单号')"></el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="GetybxcClassguoq()">{{ $t('All.搜索') }}</el-button>
      <el-button type="success" icon="el-icon-download" size="small" @click="Xbdownload">{{ $t('All.下载') }}</el-button>
      <download-excel id="Xbdownload_excel" class="export-excel-wrapper" :data="formData" :fields="json_fields" :name="Xbxcelname">
        <el-button v-show="false" type="primary" icon="download" > Download </el-button>
      </download-excel>
    </div>
    <div>
      <template>
        <el-table :data="formData" style="width: 100%">
          <el-table-column prop="Date" :label="$t('All.生产日期')" width="160">
          </el-table-column>
          <el-table-column prop="Product_name" :label="$t('All.品名')"> </el-table-column>
          <el-table-column prop="Gg" :label="$t('All.规格')"> </el-table-column>
          <el-table-column prop="ItemNo" :label="$t('All.料号')"> </el-table-column>
          <el-table-column prop="Wadoco" :label="$t('All.工单号')"> </el-table-column>
          <el-table-column prop="Machine" :label="$t('All.机台')"> </el-table-column>
          <el-table-column prop="Banbie" :label="$t('All.班别')"> </el-table-column>
          <el-table-column prop="Username" :label="$t('All.操作员')"> </el-table-column>
          <el-table-column prop="Zong" :label="$t('All.数量')"> </el-table-column>
          <el-table-column align="center" prop="State" :label="$t('All.状态')" width="120">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.State === 0 ? 'success' : scope.row.State === 2 ? 'warning' : scope.row.State === 3 ? 'primary' : 'danger'"
                effect="dark">{{ scope.row.State === 0 ? $t('All.有效') : scope.row.State === 2 ? $t('All.锁') +
                  scope.row.Order_no : scope.row.State === 3 ? $t('All.完') + scope.row.Order_no : $t('All.失效') }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Type" :label="$t('All.类型')" width="110">
            <template slot-scope="scope">
              <el-tag>{{ scope.row.Type === 0 ? $t('All.生产') : scope.row.Type === 2 ? $t('All.拆包') : scope.row.Type ===
                3 ? $t('All.退料') : scope.row.Type === 1 ? $t('All.补PASS单') : '' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" width="80" :label="$t('All.操作类型')">
            <template slot-scope="scope">
              <el-button size="mini" style="text-align: right;" type="danger"
                :disabled="!(scope.row.State === 0 && $store.state.Login.Jurisdiction <= 4 && ($store.state.Login.Department === 'SCYB' || $store.state.Login.Department === 'CT' || $store.state.Login.Department === 'All'))"
                @click="Delbs(scope.row)">Del</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination style="text-align: center; padding: 20px;" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="pageindex" :page-sizes="[10, 100, 200, 300]"
      :page-size="page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data () {
    return {
      formData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      Text: '',
      Dayscount: 365,
      Factory: 'All',
      Dates: new Date(),
      json_fields: {
        生产日期: 'Date',
        品名: 'Product_name',
        规格: 'Gg',
        料号: 'ItemNo',
        工单号: 'Wadoco',
        机台: 'Machine',
        班别: 'Banbie',
        操作员: 'Username',
        数量: 'Zong'

      },
      Xbexcelname: '一部线材' + Date.now()
    }
  },
  filters: {
    formatDate: function (value, args) {
      var dt = new Date(value)
      if (args === 'yyyy-M-d') { // yyyy-M-d
        const year = dt.getFullYear()
        const month = dt.getMonth() + 1
        const date = dt.getDate()
        return `${year}-${month}-${date}`
      } else if (args === 'yyyy-M-d H:m:s') { // yyyy-M-d H:m:s
        const year = dt.getFullYear()
        const month = dt.getMonth() + 1
        const date = dt.getDate()
        const hour = dt.getHours()
        const minute = dt.getMinutes()
        const second = dt.getSeconds()
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`
      } else if (args === 'yyyy-MM-dd') { // yyyy-MM-dd
        const year = dt.getFullYear()
        const month = (dt.getMonth() + 1).toString().padStart(2, '0')
        const date = dt.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${date}`
      } else { // yyyy-MM-dd HH:mm:ss
        const year = dt.getFullYear()
        const month = (dt.getMonth() + 1).toString().padStart(2, '0')
        const date = dt.getDate().toString().padStart(2, '0')
        const hour = dt.getHours().toString().padStart(2, '0')
        const minute = dt.getMinutes().toString().padStart(2, '0')
        const second = dt.getSeconds().toString().padStart(2, '0')
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.GetybxcClassguoq()
  },
  mounted () { },
  methods: {
    async GetybxcClassguoq () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Text: this.Text,
        Factory: this.Factory,
        Date: this.Dates,
        Dayscount: this.Dayscount
      }
      const { data: res } = await this.$http.post(
        '/api/Semifinished/GetybxcClassguoq',
        intfs
      )
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.formData = res.response.XBcsjk
    },
    Xbdownload () {
      document.getElementById('xbdownload_excel').click()
    },
    async Delbs (row) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(async () => {
          const intfs = {
            Id: row.Id,
            Serialcode: row.Serialcode,
            UserData: this.$store.state.Login,
            Type: 'CT'
          }
          const { data: res } = await this.$http.post(
            '/api/Semifinished/Delbs',
            intfs
          )
          if (res.status !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.GetybxcClassguoq()
        })
        .catch(() => {
        })
    },
    handleSizeChange (val) {
      this.page_size = val
      this.GetybxcClassguoq()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.GetybxcClassguoq()
    }
  }
}

</script>
<style lang="less" scoped>
/** talbe表头置顶**/
/deep/ .el-table {
  overflow: visible;
}

/deep/ .el-table__header-wrapper {
  position: sticky;
  top: 0px;
  z-index: 10;
}
</style>
