<template>
  <div class="wbobyy">
    <div id="wrapper">
        <div class="input-data">
            <input type="text" v-model="Noitem" @keyup.enter="UserDataSelect(Noitem)" required>
            <div class="underline">
              <i class="el-icon-circle-close" v-show="Noitem.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="Noitem = ''"></i>
              <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="UserDataSelect(Noitem)"></el-button>
            </div>
            <label>{{ $t('All.请扫描PASS单') }}</label>
            <i class="icon iconfont icon-saomiao saoma" style="position: absolute;top: 8px;right: 1px;color: #808080;" @click="this.Qecodefun"></i>
        </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: ''
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['Qcrode', 'TabComponentFun']),
    async UserDataSelect (row) {
      const listdata = row.split('/')
      if (listdata.length === 6) {
        if (listdata[4] === 'CT') {
          this.Qcrode(listdata[5])
          this.TabComponentFun('Ctfb')
          this.Addtab(this.$t('All.铜线标识卡'), 'Ctfb')
          return
        }
        if (listdata[4] === 'CY') {
          this.Qcrode(listdata[5])
          this.TabComponentFun('Cyfb')
          this.Addtab(this.$t('All.冲压') + this.$t('All.注塑标识卡'), 'Cyfb')
          return
        }
        if (listdata[4] === 'ER') {
          this.Qcrode(listdata[5])
          this.TabComponentFun('Erfb')
          this.Addtab(this.$t('All.二部标识卡'), 'Erfb')
          return
        }
        if (listdata[4] === 'ZL') {
          this.Qcrode(listdata[5])
          this.TabComponentFun('Zlfb')
          this.Addtab(this.$t('All.制粒标识卡'), 'Zlfb')
          return
        }
        if (listdata[4] === 'SelPrint') {
          this.Qcrode(listdata[5])
          this.TabComponentFun('Xbcsjkfb')
          this.Addtab(this.$t('All.一部标识卡'), 'Xbcsjkfb')
          return
        }
        if (listdata[4] === 'Pass') {
          this.Qcrode(listdata[5])
          this.TabComponentFun('Subcontractdata')
          this.Addtab(this.$t('All.检验PASS单分包'), 'Subcontractdata')
          return
        }
      }
      if (row.length === 29) {
        this.Qcrode(row)
        this.TabComponentFun('Subcontractdata')
        this.Addtab(this.$t('All.检验PASS单分包'), 'Subcontractdata')
        return
      }
      if (row.length === 31) {
        var strrow = row.slice(2, 4)
        if (strrow === 'CT') {
          this.Qcrode(row)
          this.TabComponentFun('Ctfb')
          this.Addtab(this.$t('All.铜线标识卡'), 'Ctfb')
        } else if (strrow === 'CY') {
          this.Qcrode(row)
          this.TabComponentFun('Cyfb')
          this.Addtab(this.$t('All.标识卡'), 'Cyfb')
        } else if (strrow === 'ER') {
          this.Qcrode(row)
          this.TabComponentFun('Erfb')
          this.Addtab(this.$t('All.二部标识卡'), 'Erfb')
        } else if (strrow === 'ZL') {
          this.Qcrode(row)
          this.TabComponentFun('Zlfb')
          this.Addtab(this.$t('All.制粒标识卡'), 'Zlfb')
        } else if (strrow === 'XL') {
          this.Qcrode(row)
          this.TabComponentFun('Xbcsjkfb')
          this.Addtab(this.$t('All.一部标识卡'), 'Xbcsjkfb')
        } else {
          this.Qcrode(row)
          this.TabComponentFun('Subcontractdata')
          this.Addtab(this.$t('All.检验PASS单分包'), 'Subcontractdata')
        }
        return
      }
      const strArr = row.split('#')
      if (strArr.length === 11) {
        this.Qcrode(strArr[9])
        this.TabComponentFun('Subcontractdata')
        this.Addtab(this.$t('All.标识卡'), 'Subcontractdata')
        this.Noitem = ''
      } else {
        this.Noitem = ''
        alert(this.$t('All.二维码数据异常'))
      }
    },
    Qecodefun () {
      this.TabComponentFun('Qecode')
      this.Addtab(this.$t('All.二维码'), 'Qecode')
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  height: 0px;
  width: 300px;
}
</style>
