<template>
  <div class="Examine">
    <div>
      <el-select v-if="$store.state.Login.Jurisdiction == 0" size="medium" v-model="Factory"
        style="width: 150px; padding: 0px 0px 0px 10px;" clearable :placeholder="$t('system.选择厂区')">
        <el-option v-for="item in $PublicJs.factory" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-date-picker style="width: 140px; padding: 0px 0px 0px 10px;" v-model="Dates" type="date" placeholder="选择日期"
        format="yyyy-MM-dd" value-format="yyyy-MM-dd">
      </el-date-picker>
      <el-select size="medium" v-model="Dayscount" style="width: 90px; padding: 0px 0px 0px 10px;" filterable
        allow-create>
        <el-option label="180" :value="180" />
        <el-option label="365" :value="365" />
      </el-select>
      <el-input size="medium" clearable v-model="Text" style="width: 270px; padding: 0px 10px 0px 10px;"
        :placeholder="$t('All.品名') + '/' + $t('All.工单号') + '/' + $t('All.系统单号')"></el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="TraceQueryguoq()">{{ $t('All.搜索') }}</el-button>
      <el-button type="success" icon="el-icon-download" size="small" @click="PASSdownload">{{ $t('All.下载') }}</el-button>
      <download-excel id="passdownload_excel" class="export-excel-wrapper" :data="tableData" :fields="json_fields" :name="PASSexcelname">
        <el-button v-show="false" type="primary" icon="download" > Download </el-button>
      </download-excel>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" width="60">
          </el-table-column>
          <el-table-column align="center" prop="Numbers" :label="'IQC' + this.$t('All.单号')" min-width="160"></el-table-column>
          <el-table-column align="center" prop="Date" :label="this.$t('system.创建日期')" width="160"></el-table-column>
          <el-table-column align="center" prop="Order_Number" min-width="100" :label="this.$t('All.订单号')"></el-table-column>
          <el-table-column align="center" prop="Item_No" :label="$t('All.料号')"  min-width="160"></el-table-column>
          <el-table-column align="center" prop="Supplier" :label="this.$t('All.厂商名称')"></el-table-column>
          <el-table-column align="center" prop="Product_name" :label="$t('All.品名')"></el-table-column>
          <el-table-column align="center" prop="Warehousing" :label="this.$t('All.入库数')"></el-table-column>
          <el-table-column align="center" prop="Box" :label="this.$t('All.箱数')"></el-table-column>
          <el-table-column align="center" prop="Boxquantity" :label="this.$t('All.单箱数量')"></el-table-column>
          <el-table-column align="center" prop="Count" :label="this.$t('All.分包几次')"></el-table-column>
          <el-table-column align="center" prop="Operator" :label="this.$t('All.检验员')"></el-table-column>
          <el-table-column align="center" prop="User" :label="this.$t('All.操作员')"></el-table-column>
          <el-table-column align="center" prop="Type" :label="this.$t('All.操作类型')" width="110px">
            <template slot-scope="scope">
              <el-tag>{{ scope.row.Type === 0?$t('All.进料'):scope.row.Type === 2?$t('All.拆包'):scope.row.Type === 3?$t('All.退料'):scope.row.Type === 1?$t('All.补PASS单'):'' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="this.$t('All.状态')" width="120" fixed="right">
            <template slot-scope="scope" >
              <el-tag :type="scope.row.State === 0?'success':scope.row.State === 2?'warning':scope.row.State === 3?'primary':'danger'" effect="dark">{{ scope.row.State === 0?$t('All.有效'):scope.row.State === 2?$t('All.锁') + scope.row.Order_no:scope.row.State === 3?$t('All.完') + scope.row.Order_no:$t('All.失效') }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column  align="center" fixed="right" width="150" :label="this.$t('All.操作类型')">
            <template slot-scope="scope">
              <el-button size="mini" style="text-align: right;" type="danger" :disabled="$store.state.Login.Department !== 'All'" @click="del(scope.row)">Del</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 50, 100, 500]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      Text: '',
      Dayscount: 365,
      Factory: 'All',
      Dates: new Date(),
      dialogVisible: false,
      prindata: '',
      printer: {},
      json_fields: {
        IQC单号: 'Numbers',
        创建日期: 'Date',
        订单号: 'Order_Number',
        料号: 'Item_No',
        厂商名称: 'Supplier',
        品名: 'Product_name',
        入库数: 'Warehousing',
        箱数: 'Box',
        单箱数量: 'Boxquantity',
        分包几次: 'Count',
        检验员: 'Operator',
        操作员: 'User'
      },
      PASSexcelname: 'PASS单' + Date.now()
    }
  },
  created () {
    this.TraceQueryguoq()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData', 'TabComponentFun']),
    async TraceQueryguoq () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Text: this.Text,
        Factory: this.Factory,
        Date: this.Dates,
        Dayscount: this.Dayscount
      }
      const { data: res } = await this.$http.post('/api/Trace/TraceQueryguoq', intfs)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.Traceprin
    },
    PASSdownload () {
      document.getElementById('passdownload_excel').click()
    },
    Setquer () {
      this.pageindex = 1
      this.TraceQueryguoq()
    },
    async chongxindata (row) {
      this.prindata = row
      this.dialogVisible = true
    },
    handleSizeChange (val) {
      this.page_size = val
      this.TraceQueryguoq()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.TraceQueryguoq()
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    async del (row) {
      const loadingInstance = this.$loading({ text: this.$t('All.删除中') + '...', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Trace/DelTraceprin', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      this.$message.success(res.msg)
      this.dialogVisible = false
      this.TraceQueryguoq()
      loadingInstance.close()
    }
  }
}
</script>
<style lang="less" scoped>
  /** talbe表头置顶**/
  /deep/ .el-table {
    overflow: visible;
 }

 /deep/ .el-table__header-wrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
 }
</style>
